
const baseurl = "https://encryption.edukrypt.app/lmsapi";
const apikey = "e1b75fd9e0bb7864e725e168c46344fb";
const domain = "https://test.com";




export {
    baseurl,
    apikey,
    domain
} ;