import React from 'react'
import '../../assets/libs/@mdi/font/css/materialdesignicons.min.css';
import '../../assets/css/tailwind.css';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';




export default function Navbar(props) {
    const cookie = new Cookies();
    let { navClass, navJustify } = props;
    let [isMenu, setisMenu] = useState(false);
    let [manu , setManu] = useState('');
    let location = useLocation();
    const {navbarReducer} = useSelector(response => response);
    let [isAccount , setIsAccount] = useState(false);
    let [userdata, setUserdata] = useState(null);
   const [logo, setLogo] = useState();
    //const site_logo = useSelector((state) => state.websiteReducer.data.data.site_logo);
    const isLogin = cookie.get('icp_authData') ? true : false;
    const [navData,setNavData] = useState(null);

    
    useEffect(()=>{
       
        let current = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
        setManu(current)
       
        function windowScroll() {
            const navbar = document.getElementById("topnav");
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                if (navbar !== null) {
                    navbar?.classList.add("nav-sticky");
                }
            } else {
                if (navbar !== null) {
                    navbar?.classList.remove("nav-sticky");
                }
            }
        }
        window.addEventListener("scroll", windowScroll);
        window.scrollTo(0, 0)
        return () => {
            window.removeEventListener('scroll', windowScroll);
        };

    },[location.pathname.substring(location.pathname.lastIndexOf('/') + 1)]);
    
    useEffect(()=>{

        
        if(cookie.get('icp_authData'))
        {
            const user = cookie.get('icp_authData');
            setUserdata(user);
        }
        if(cookie.get('icp_favicon'))
            {
                const faviData = cookie.get('icp_favicon');
                setLogo(faviData.site_logo);
            }
        getNavData();
        
        
    },[]);
    

    const getNavData = ()=>{
       
        /*if(navbarReducer.status === 1)
            {
               
                
            }*/
            if(navbarReducer.data.status ===1)
            {
                setNavData(navbarReducer.data.data);
            }
    }
    
    const toggleMenu = () => {
        setisMenu(!isMenu);
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    };

    //console.log(fullWebData);

    return (
        <nav id="topnav" className={`defaultscroll ${navClass === "nav-light" ? '' : navClass === "nav-sticky" ?
        'bg-white dark:bg-slate-900' : ''}`}>
        <div className="container relative">
            {
                navClass === "nav-light" ?
                    <Link className="logo" to="/index">
                        <span className="inline-block dark:hidden">
                            <img src={logo ? logo : ''} className="l-dark width20" height="14" alt="logo" />
                            <img src={logo ? logo : ''} className="l-light width20" height="14" alt="logo" />
                        </span>
                        <img src="{fullWebData.data ? fullWebData.data.data.site_logo : ''}" height="24" className="hidden dark:inline-block" alt="logo" />
                    </Link>
                    :
                    <Link className="logo" to="/index">
                        <img src={logo ? logo : ''} className="inline-block dark:hidden width20" alt="logo" />
                        <img src={logo ? logo : ''} className="hidden dark:inline-block width20" alt="logo" />
                    </Link>
            }

            <div className="menu-extras">
                <div className="menu-item">
                    <Link to="#" className={`navbar-toggle ${isMenu ? 'open' : ''}`} id="isToggle" onClick={() => toggleMenu()}>
                        <div className="lines">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </Link>
                </div>
            </div>

            
           
            <div id="navigation" style={{ display: isMenu ? 'block' : 'none' }}>
                <ul className={`navigation-menu ${navClass} ${navJustify}`}>
                    

                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="/">Home</Link>
                    </li>

                    {/*<li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="/about">About Us</Link>
                       
                    </li>*/}
                    {navData ?
                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to={"/page/"+(navData ? navData.slug : '')}>{navData ? navData.blog_title : ''}</Link>
                       
                    </li>: ''}
                    <li className={`${["page-aboutus", "page-services","page-team", "page-pricing","page-testimonial","user-profile","user-billing","user-payment","user-invoice","user-notification","user-setting","property-listing","property-detail","course-listing","course-detail","nft-explore","nft-auction","nft-collection","nft-creators","nft-creator-profile","nft-creator-profile-edit","nft-wallet","nft-create-item","nft-detail","food-recipe","shop-grid","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","food-recipe","shop-grid","shop-grid-two","shop-item-detail","shop-cart","shop-checkout","shop-account","photography-about","photography-portfolio","page-job-grid","page-job-detail","page-job-apply","page-job-post","page-job-career","page-job-candidates","page-job-candidate-detail","page-job-companies","page-Job-company-detail","forums-topic","forums-comments","helpcenter-overview","helpcenter-faqs","helpcenter-guides","helpcenter-support","blog","blog-sidebar","blog-detail","blog-standard-post","blog-slider-post","blog-gallery-post","blog-youtube-post","blog-vimeo-post","blog-audio-post","blog-blockquote-post","blog-left-sidebar-post","email-confirmation","email-password-reset","email-alert","email-invoice","auth-login","auth-signup","auth-re-password","auth-lock-screen","page-terms","page-privacy","page-comingsoon","page-maintenance","page-error","page-thankyou","contact-detail","contact-one","contact-two"].includes(manu)? "active" : ""} has-submenu parent-parent-menu-item`}>
                        <Link to="/category">Course</Link>
                       
                    </li>

                    
                    <li className={`${["ui-components", "documentation","changelog"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/gallery">Gallery</Link></li>
                    <li className={`${["ui-components", "documentation","changelog"].includes(manu)? "active" : ""} has-submenu parent-menu-item`}><Link to="/blog">Blog</Link></li>
        
                    
                    {
                    isLogin 
                    ? <li className="dropdown inline-block relative ms-1 header_profile ms-4">
                        <button onClick={()=>setIsAccount(!isAccount)} data-dropdown-toggle="dropdown" className="dropdown-toggle size-9 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center rounded-full bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 hover:border-indigo-700 text-white" type="button">
                            <Link to="/profile"><i className="mdi mdi-account"></i> </Link>                       
                        </button>
                    </li> :
                    <li className=" inline-block text-white relative ms-1 header_profile header_login_btn mt-1">
                    
                    
                        <Link to="/login" ><span className="text-white">Login</span></Link>                     
                    </li>}
                    

                   
                </ul>
                
            </div>
        </div >
    </nav >
    )
}
