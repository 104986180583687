import React, { useState, useEffect } from 'react';

import {LiaTimesSolid} from "react-icons/lia";
import Cookies from "universal-cookie";


const CookieModal = () => {

    const [isOpen, setIsOpen] = useState(true);
    const cookie = new Cookies();

    useEffect(()=>{
        if(cookie.get("icp_user_cookie_request"))
            {
                setIsOpen(false);
            }
    },[]);

    const handleCookie = ()=>{
        
        cookie.set("icp_user_cookie_request","accept");
        if(cookie.get("icp_user_cookie_request"))
        {
            setIsOpen(false);
        }
    }

    return <div className="cookie-popup fixed max-w-lg bottom-3 end-3 start-3 sm:start-0 mx-auto bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md py-5 px-6 z-50" style={{display : isOpen ? 'block' :'none'}}>
        <p className="text-slate-400">This website uses cookies to provide you with a great user experience. By using it, you accept our 
            <button onClick={handleCookie} 

className="text-emerald-600 dark:text-emerald-500 font-semibold px-2">use of cookies</button></p>
        <div className="cookie-popup-actions text-end">
            <button onClick={() => setIsOpen(false)} className="absolute border-none bg-none p-0 cursor-pointer font-semibold top-2 end-2"><LiaTimesSolid className="text-dark dark:text-slate-200 text-xl"/></button> </div>
    </div>
}

export default CookieModal;